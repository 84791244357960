import classNames from 'classnames';
import { InnerContainer } from '../../common/inner-container';
import { ILabIcon } from '../../icons/icons';
import { RelLink } from '../../rel-link';

export const Bottom = ({ className, ...props }: any) => {
  return (
    <div className={classNames('bg-black100 ', className)} {...props} data-testid="footerBottom">
      <div className="flex ">
        <InnerContainer>
          <div className="container mx-auto flex flex-col items-center justify-between gap-6 border-t border-black300 pt-6 text-xs text-black600 md:flex-row md:items-center md:justify-items-start md:border-0 md:pt-8  md:text-sm">
            <div className="inline-flex md:whitespace-nowrap">
              HangiKredi.com bir{/* */}
              <span className="mx-2">
                <ILabIcon height={24} width={65} />
              </span>
              {/* */}
              grup şirketidir.
            </div>
            <div className="">
              <ul
                className="flex flex-wrap justify-evenly gap-2 text-xs md:gap-1 md:text-sm"
                data-testid="ilabLinkList"
              >
                <li className="flex min-w-[48px] rounded-[90px] bg-white px-2 py-1 md:flex-nowrap md:gap-1 md:bg-transparent md:p-0 md:after:content-['-']">
                  <RelLink
                    className="inline-block w-full text-center"
                    href={'https://www.kariyer.net/'}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    Kariyer.net
                  </RelLink>
                </li>
                <li className="flex min-w-[48px] rounded-[90px] bg-white px-2 py-1 md:flex-nowrap md:gap-1 md:bg-transparent md:p-0 md:after:content-['-']">
                  <RelLink
                    className="inline-block w-full text-center"
                    href={'https://www.sigortam.net/'}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    Sigortam.net
                  </RelLink>
                </li>
                <li className="flex min-w-[48px] rounded-[90px] bg-white px-2 py-1 md:flex-nowrap md:gap-1 md:bg-transparent md:p-0 md:after:content-['-']">
                  <RelLink
                    className="inline-block w-full text-center"
                    href={'https://www.arabam.com/'}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    Arabam.com
                  </RelLink>
                </li>
                <li className="flex min-w-[48px] rounded-[90px] bg-white px-2 py-1 md:flex-nowrap md:gap-1 md:bg-transparent md:p-0 md:after:content-['-']">
                  <RelLink
                    className="inline-block w-full text-center"
                    href={'https://www.cimri.com/'}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    Cimri
                  </RelLink>
                </li>
                <li className="flex min-w-[48px] rounded-[90px] bg-white px-2 py-1 md:flex-nowrap md:gap-1 md:bg-transparent md:p-0 md:after:content-['-']">
                  <RelLink
                    className="inline-block w-full text-center"
                    href={'https://www.emlakjet.com/'}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    Emlakjet
                  </RelLink>
                </li>
                <li className="flex min-w-[48px] rounded-[90px] bg-white px-2 py-1 md:flex-nowrap md:gap-1 md:bg-transparent md:p-0 md:after:content-['-']">
                  <RelLink
                    className="inline-block w-full text-center"
                    href={'https://www.endeksa.com/tr/'}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    Endeksa
                  </RelLink>
                </li>
                <li className="flex min-w-[48px] rounded-[90px] bg-white px-2 py-1 md:flex-nowrap md:gap-1 md:bg-transparent md:p-0 md:after:content-['-']">
                  <RelLink
                    className="inline-block w-full text-center"
                    href={'https://www.hangikredi.com/'}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    HangiKredi
                  </RelLink>
                </li>
                <li className="flex min-w-[48px] rounded-[90px] bg-white px-2 py-1 md:flex-nowrap md:gap-1 md:bg-transparent md:p-0 md:after:content-['-']">
                  <RelLink
                    className="inline-block w-full text-center"
                    href={'https://www.neredekal.com/'}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    Neredekal
                  </RelLink>
                </li>
                <li className="flex min-w-[48px] rounded-[90px] bg-white px-2 py-1 md:flex-nowrap md:gap-1 md:bg-transparent md:p-0 md:after:content-['-']">
                  <RelLink
                    className="inline-block w-full text-center"
                    href={'https://www.chemorbis.com/tr/'}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    ChemOrbis
                  </RelLink>
                </li>
                <li className="min-w-[48px] rounded-[90px] bg-white px-2 py-1 md:bg-transparent md:p-0 ">
                  <RelLink
                    className="inline-block w-full text-center"
                    href={'https://www.steelorbis.com/'}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    SteelOrbis
                  </RelLink>
                </li>
              </ul>
            </div>
          </div>
        </InnerContainer>
      </div>
    </div>
  );
};
