import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  prZero?: boolean;
  pyZero?: boolean;
  pxZero?: boolean;
  className?: string;
};

export const InnerContainer: React.FC<Props> = ({ children, prZero, pyZero, pxZero, className, ...props }: any) => {
  return (
    <div
      {...props}
      className={classNames(
        'md:px-0 container mx-auto',
        { 'pr-0': prZero, 'py-0': pyZero, 'px-0': pxZero },
        [pxZero ? 'px-0' : 'px-4'],
        className
      )}
    >
      {children}
    </div>
  );
};
