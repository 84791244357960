import { DeviceType } from '@/src/interfaces/data-contracts';

export const getDeviceType = (): DeviceType | 'Unknown' => {
  if (typeof window !== 'undefined') {
    const userAgent = window.navigator.userAgent;

    if (userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop|Googlebot Smartphone/i)) {
      return DeviceType.Mobile;
    } else if (userAgent.match(/Windows NT|Macintosh|Linux|X11|Windows|Mac OS X|Googlebot|Googlebot Desktop/i)) {
      return DeviceType.Desktop;
    }

    return DeviceType.Mobile;
  }

  return 'Unknown';
};
