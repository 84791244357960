interface Cookies {
  [key: string]: string;
}

export const getCookiesClient = () => {
  const cookies: Cookies = {};

  if (typeof document === 'undefined') return;

  document.cookie.split(';').forEach((splitCookie) => {
    const cookieParts = splitCookie.trim().split('=');
    if (cookieParts.length >= 2) {
      const cookieKey = cookieParts[0];
      const cookieVal = cookieParts[1];
      cookies[cookieKey] = decodeURIComponent(cookieVal);
    } else {
      console.warn('Malformed cookie');
    }
  });
  return cookies;
};
