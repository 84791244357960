import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getDeviceType } from '@/src/store/get-device-type';
import { generateGUID } from '@/lib/generate-guid';

export const subscribeService = createApi({
  reducerPath: 'subscribeService',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers) => {
      headers.set('device', getDeviceType());
      headers.set('CorrelationId', generateGUID());
      return headers;
    },
  }),
  endpoints: (builder) => ({
    subscribe: builder.mutation({
      query: (data) => ({
        url: '/pages/customer/subscribes',
        method: 'POST',
        headers: {
          device: 'desktop',
        },
        body: data,
      }),
    }),
  }),
});

export const { useSubscribeMutation }: any = subscribeService;
