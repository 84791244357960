import { Bottom } from './bottom/bottom';
import { Kvkk } from './kvkk/kvkk';

const FooterSimple = () => {
  return (
    <footer className="flex flex-col" data-testid="footer">
      <Kvkk className="py-5 md:py-8" />
      <Bottom />
    </footer>
  );
};

export default FooterSimple;
