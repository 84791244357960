import {
  ISendOtpRequest,
  ISendOtpResponse,
  IVerifyOtpRequest,
  IVerifyOtpResponse,
} from '@/src/interfaces/missing-types';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getDeviceType } from '@/src/store/get-device-type';
import { generateGUID } from '@/lib/generate-guid';

export const otpService = createApi({
  reducerPath: 'otpService',
  baseQuery: fetchBaseQuery({
    baseUrl: '/auth-api',
    prepareHeaders: (headers) => {
      headers.set('device', getDeviceType());
      headers.set('CorrelationId', generateGUID());
      return headers;
    },
  }),
  endpoints: (builder) => ({
    sendOtp: builder.mutation<ISendOtpResponse, ISendOtpRequest>({
      query: (body) => ({
        url: '/api/Otp/Send',
        method: 'POST',
        body,
      }),
    }),

    verifyOtp: builder.mutation<IVerifyOtpResponse, IVerifyOtpRequest>({
      query: (body) => ({
        url: '/api/Otp/Verify',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useSendOtpMutation, useVerifyOtpMutation } = otpService;
