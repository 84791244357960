import classNames from 'classnames';
import { AngledArrowDownIcon } from '../../icons/icons';
import { RelLink } from '../../rel-link';
import { ResponseMenuItem } from '@/src/interfaces/data-contracts';
import printBadgeType from '@/src/utils/helpers/badge-type';

type Props = {
  item: ResponseMenuItem;
};

export const NavItem: React.FC<Props> = ({ item }) => {
  return (
    <>
      {item.subMenuItemList && item.subMenuItemList.length > 0 ? (
        <div
          className={classNames(
            'navLink after:bg-blue-400 relative flex h-full cursor-pointer flex-nowrap items-center text-sm font-semibold text-black900 transition-all duration-300 after:absolute after:bottom-0 after:left-0 after:h-px after:w-0 after:transition-all after:duration-300 after:hover:w-full'
          )}
        >
          <span title={item.name ?? ''} data-testid="name">
            {item.name}
          </span>
          {item.subMenuItemList && item.subMenuItemList.length > 0 && (
            <AngledArrowDownIcon className={classNames('arrow ml-2 transition-all duration-300')} />
          )}
          {item.badgeType && (
            <span
              className={classNames(
                { 'bg-introLight text-intro': 10 === (item.badgeType as unknown as number) },
                { 'bg-purple50 text-purple404': 20 === (item.badgeType as unknown as number) },
                { 'bg-orange50 text-orange500': 30 === (item.badgeType as unknown as number) },
                'badge ml-2 rounded p-1 text-xs  font-semibold'
              )}
              data-testid="badge"
            >
              {printBadgeType[item.badgeType]}
            </span>
          )}
        </div>
      ) : (
        <RelLink
          className={classNames(
            'navLink after:bg-blue-400 relative flex h-full flex-nowrap items-center text-sm font-semibold text-black900 transition-all duration-300 after:absolute after:bottom-0 after:left-0 after:h-px after:w-0 after:transition-all after:duration-300 after:hover:w-full'
          )}
          href={item.url!}
          data-testid="link"
        >
          <span className={classNames()} title={item.name ?? ''} data-testid="name">
            {item.name}
          </span>
          {item.badgeType && (
            <span
              className={classNames(
                { 'bg-introLight text-intro': 10 === (item.badgeType as unknown as number) },
                { 'bg-purple50 text-purple404': 20 === (item.badgeType as unknown as number) },
                { 'bg-orange50 text-orange500': 30 === (item.badgeType as unknown as number) },
                'badge ml-2 rounded p-1 text-xs  font-semibold'
              )}
              data-testid="badge"
            >
              {printBadgeType[item.badgeType]}
            </span>
          )}
        </RelLink>
      )}
    </>
  );
};
