import { isQuery } from '@/src/utils/helpers/isQuery';
import Link, { LinkProps } from 'next/link';
import { AnchorHTMLAttributes } from 'react';

type anchorProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps>;

interface Props extends anchorProps, LinkProps {
  children: React.ReactNode;
  passHref?: boolean;
}

export const RelLink: React.FC<Props> = ({ children, passHref = true, ...props }) => {
  const hasQuery = isQuery(props.href as string);

  return (
    <Link rel={hasQuery ? 'nofollow' : undefined} {...props} passHref={passHref}>
      {children}
    </Link>
  );
};
