import { PosProductListPageModel, PosproductBanksListData } from '@/src/interfaces/data-contracts';

import { getDeviceType } from '@/src/store/get-device-type';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generateGUID } from '@/lib/generate-guid';

export const posListService = createApi({
  reducerPath: 'posListService',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers) => {
      headers.set('device', getDeviceType());
      headers.set('CorrelationId', generateGUID());
      return headers;
    },
  }),
  endpoints: (build) => ({
    getPosList: build.query<PosProductListPageModel, any>({
      query: (params: any) => {
        const query = new URLSearchParams(params.query);
        query.append('Component', 'List');
        query.append('Order', 'GeneralOrder');
        return {
          url: `/pages/posproduct/list?${query}`,
          method: 'GET',
          headers: {
            device: params.device,
          },
        };
      },
    }),
    getPosPhysical: build.query<PosProductListPageModel, any>({
      query: (params: any) => {
        const query = new URLSearchParams(params.query);
        query.append('Component', 'List');
        query.append('Order', 'GeneralOrder');
        return {
          url: `/pages/posproduct/physical?${query}`,
          method: 'GET',
          headers: {
            device: params.device,
          },
        };
      },
    }),
    getPosVirtual: build.query<PosProductListPageModel, any>({
      query: (params: any) => {
        const query = new URLSearchParams(params.query);
        query.append('Component', 'List');
        query.append('Order', 'GeneralOrder');
        return {
          url: `/pages/posproduct/virtual?${query}`,
          method: 'GET',
          headers: {
            device: params.device,
          },
        };
      },
    }),
    getPosDigital: build.query<PosProductListPageModel, any>({
      query: (params: any) => {
        const query = new URLSearchParams(params.query);
        query.append('Component', 'List');
        query.append('Order', 'GeneralOrder');
        return {
          url: `/pages/posproduct/digital?${query}`,
          method: 'GET',
          headers: {
            device: params.device,
          },
        };
      },
    }),
    getPosMobile: build.query<PosProductListPageModel, any>({
      query: (params: any) => {
        const query = new URLSearchParams(params.query);
        query.append('Component', 'List');
        query.append('Order', 'GeneralOrder');
        return {
          url: `/pages/posproduct/mobile?${query}`,
          method: 'GET',
          headers: {
            device: params.device,
          },
        };
      },
    }),
    getPosBank: build.query<PosProductListPageModel, any>({
      query: (params: any) => {
        const query = new URLSearchParams(params.query);
        query.append('Component', 'List');
        query.append('Order', 'GeneralOrder');
        return {
          url: `/pages/posproduct/${params.bankSeoUrl}?${query}`,
          method: 'GET',
          headers: {
            device: params.device,
          },
        };
      },
    }),
    getPosProduct: build.query<PosProductListPageModel, any>({
      query: (params: any) => {
        const query = new URLSearchParams(params.query);
        query.append('Component', 'Detail');
        return {
          url: `/pages/posproduct/detail/${params.bankSeoUrl}/${params.productSeoUrl}?${query}`,
          method: 'GET',
          headers: {
            device: params.device,
          },
        };
      },
    }),
    getPosAllBanks: build.query<PosproductBanksListData, any>({
      query: (params: any) => {
        const query = new URLSearchParams(params.query);
        query.append('Component', 'List');
        query.append('Order', 'GeneralOrder');
        return {
          url: `/pages/posproduct/banks?${query}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetPosListQuery,
  useLazyGetPosListQuery,
  useGetPosPhysicalQuery,
  useLazyGetPosPhysicalQuery,
  useGetPosVirtualQuery,
  useLazyGetPosVirtualQuery,
  useGetPosDigitalQuery,
  useLazyGetPosDigitalQuery,
  useGetPosMobileQuery,
  useLazyGetPosMobileQuery,
  useGetPosBankQuery,
  useLazyGetPosBankQuery,
  useGetPosProductQuery,
  useLazyGetPosProductQuery,
  useGetPosAllBanksQuery,
  useLazyGetPosAllBanksQuery,
} = posListService;
