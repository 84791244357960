import { getDeviceType } from '@/src/store/get-device-type';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generateGUID } from '@/lib/generate-guid';

export const userService = createApi({
  reducerPath: 'userService',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/AuthServer',
    prepareHeaders: (headers) => {
      headers.set('device', getDeviceType());
      headers.set('CorrelationId', generateGUID());
      return headers;
    },
  }),
  endpoints: (builder) => ({
    applyForm: builder.mutation<any, FormData>({
      query: (body) => ({
        url: '/Signup',
        method: 'POST',
        body,
      }),
    }),
    forgotPasswordApplyForm: builder.mutation<any, FormData>({
      query: (body) => ({
        url: '/auth/forgotpassword?apidogToken=wAnsyLJ70QbBhxKHpwiyx',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useApplyFormMutation, useForgotPasswordApplyFormMutation } = userService;
