import classNames from 'classnames';
import { RelLink } from '../../rel-link';
import React from 'react';
import { ResponseMenuItem } from '@/src/interfaces/data-contracts';

type Props = {
  children?: React.ReactNode;
  className?: string;
  footerItems?: ResponseMenuItem[];
};
export const Links: React.FC<Props> = ({ className, footerItems, ...props }: any) => {
  return (
    <div className={classNames('flex flex-1 justify-between', className)} {...props} data-testid="linkList">
      {footerItems.map((item: ResponseMenuItem, index: number) => (
        <figure key={item?.name! + index} data-testid="subLinkList">
          <figcaption className="mb-5 text-[14px] font-bold text-black1000" data-testid="topLink">
            {item.url && item.url !== 'null' ? (
              <RelLink href={item.url} key={item.url + index} data-testid="link">
                <span data-testid="name">{item.name}</span>
              </RelLink>
            ) : (
              <span data-testid="name">{item.name}</span>
            )}
          </figcaption>
          {item.subMenuItemList && item.subMenuItemList?.length > 0 && (
            <ul data-testid="list">
              {item?.subMenuItemList.map((child: any, index: any) => (
                <li
                  key={child.url + index}
                  className="mb-3 text-[14px] text-black700 last:mb-0"
                  data-testid="subLinkItem"
                >
                  <RelLink href={child.url} data-testid="link">
                    <span data-testid="name">{child.name}</span>
                  </RelLink>
                </li>
              ))}
            </ul>
          )}
        </figure>
      ))}
    </div>
  );
};
