import { ResponseStoreFrontPosProduct } from '@/src/interfaces/data-contracts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: { posListData: ResponseStoreFrontPosProduct[] | null } = {
  posListData: [],
};

//
const posListSlice = createSlice({
  name: 'posList',
  initialState,
  reducers: {
    setPosListData: (
      state,
      action: PayloadAction<ResponseStoreFrontPosProduct[] | null | undefined>
    ) => {
      state.posListData = action.payload ?? null;
    },
  },
});

export const { setPosListData } = posListSlice.actions;
export default posListSlice.reducer;
