import { Pages } from '@/src/interfaces/pages-route';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import PosproductRecourseFormDetail = Pages.PosproductRecourseFormDetail;
import PosproductRecourseCompanyFormDetail = Pages.PosproductRecourseCompanyFormDetail;
import PosproductRecourseCreate = Pages.PosproductRecourseCreate;
import { getDeviceType } from '@/src/store/get-device-type';
import { generateGUID } from '@/lib/generate-guid';

export const posFormService = createApi({
  reducerPath: 'posFormService',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers) => {
      headers.set('device', getDeviceType());
      headers.set('CorrelationId', generateGUID());
      return headers;
    },
  }),
  endpoints: (build) => ({
    getPosRecourseForm: build.query<
      PosproductRecourseFormDetail.ResponseBody,
      PosproductRecourseFormDetail.RequestParams & PosproductRecourseFormDetail.RequestHeaders
    >({
      query: (params) => ({
        url: `/pages/posproduct/${params.productId}/recourse/form`,
        method: 'GET',
        headers: {
          device: params.device,
          userId: params.userId?.toString(),
        },
      }),
    }),
    getPosRecourseCompanyForm: build.query<
      PosproductRecourseCompanyFormDetail.ResponseBody,
      PosproductRecourseCompanyFormDetail.RequestParams & PosproductRecourseCompanyFormDetail.RequestHeaders
    >({
      query: (params) => ({
        url: `/pages/posproduct/${params.productId}/recourse/companyForm`,
        method: 'GET',
        headers: {
          device: params.device,
          userId: params.userId?.toString(),
        },
      }),
    }),
    submitPosRecourseForm: build.mutation<
      PosproductRecourseCreate.ResponseBody,
      PosproductRecourseCreate.RequestBody &
        PosproductRecourseCreate.RequestParams &
        PosproductRecourseCreate.RequestHeaders
    >({
      query: ({ recourseStatus, ...rest }) => ({
        url: `/pages/posproduct/${rest.productId}/recourse`,
        method: 'POST',
        headers: {
          recourseStatus,
        },
        body: rest,
      }),
    }),
  }),
});

export const { useSubmitPosRecourseFormMutation, useGetPosRecourseFormQuery, useGetPosRecourseCompanyFormQuery } =
  posFormService;
