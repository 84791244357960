'use client';
import { AngledArrowDownIcon } from '@/src/features/ui/icons/icons';
import { RelLink } from '@/src/features/ui/rel-link';
import classNames from 'classnames';
import { useState } from 'react';
import { ResponseMenuItem } from '@/src/interfaces/data-contracts';
import printBadgeType from '@/src/utils/helpers/badge-type';

export const MobileMenuItem = (props: { menuItem: ResponseMenuItem; setOpenMenu: Function; openMenu: boolean }) => {
  const item = props.menuItem;
  const openMenu = props.openMenu;
  const setOpenMenu = props.setOpenMenu;
  const [openSubmenu, setOpenSubmenu] = useState<boolean>(false);
  const subMenuItemList =
    item?.subMenuItemList && item?.subMenuItemList.sort((a, b) => a?.displayOrder! - b?.displayOrder!);
  return (
    <li
      className={classNames(openSubmenu === true ? 'activedItem' : 'closedItem', 'border-b border-b-border')}
      data-testid="menuItem"
    >
      {subMenuItemList && subMenuItemList.length > 0 ? (
        <>
          <button
            className={'accTitle flex w-full items-center justify-between px-4 py-4 text-base font-semibold'}
            onClick={() => setOpenSubmenu(!openSubmenu)}
          >
            <span
              className={classNames(openSubmenu ? 'text-purple404' : 'text-black900', 'name')}
              title={item.name ?? ''}
              data-testid="name"
            >
              {item.name}
            </span>
            {item.badgeType && (
              <span
                className={classNames(
                  { 'bg-introLight text-intro': 10 === (item.badgeType as unknown as number) },
                  { 'bg-purple50 text-purple404': 20 === (item.badgeType as unknown as number) },
                  { 'bg-orange50 text-orange500': 30 === (item.badgeType as unknown as number) },
                  'badge rounded p-1 text-xs font-semibold  '
                )}
                data-testid="badge"
              >
                {printBadgeType[item.badgeType]}
              </span>
            )}
            <AngledArrowDownIcon className={'arrow ml-auto transition-all duration-300'} />
          </button>
          <nav className={classNames('subMenu hidden px-4 transition-all duration-300')} data-testid="nav">
            <ul className="flex flex-col gap-5 py-5 pt-0" data-testid="headerSubMenu">
              {subMenuItemList &&
                subMenuItemList.map((subItem: ResponseMenuItem, index) => (
                  <li key={index} data-testid="subMenuItem">
                    {subItem.url && subItem.url !== 'null' ? (
                      <RelLink
                        href={subItem.url}
                        onClick={() => setOpenMenu(!openMenu)}
                        className={classNames('flex gap-3 text-base', {
                          ['pointer-events-none']: (subItem.badgeType as unknown as number) === 30,
                        })}
                        title={subItem.name ?? ''}
                      >
                        <p
                          className={classNames('font-semibold text-black700', {
                            ['opacity-50']: (subItem.badgeType as unknown as number) === 30,
                          })}
                          data-testid="name"
                        >
                          {subItem.hamburgerName ? subItem.hamburgerName : subItem.name}
                        </p>
                        {subItem.badgeType && (
                          <span
                            className={classNames(
                              { 'bg-introLight text-intro': 10 === (subItem.badgeType as unknown as number) },
                              { 'bg-purple50 text-purple404': 20 === (subItem.badgeType as unknown as number) },
                              { 'bg-orange50 text-orange500': 30 === (subItem.badgeType as unknown as number) },
                              'badge rounded p-1 text-xs font-semibold  '
                            )}
                            data-testid="badge"
                          >
                            {printBadgeType[subItem.badgeType]}
                          </span>
                        )}
                      </RelLink>
                    ) : (
                      <div title={subItem.name ?? ''} data-testid="name">
                        {subItem.name}
                      </div>
                    )}
                  </li>
                ))}
            </ul>
          </nav>
        </>
      ) : item.url !== 'null' ? (
        <RelLink
          href={item?.url!}
          className={classNames(
            { ['pointer-events-none']: 30 === (item.badgeType as unknown as number) },
            'accTitle flex border-b border-b-border px-4 py-4 text-base font-semibold text-black900'
          )}
          onClick={() => setOpenMenu(!openMenu)}
          data-testid="link"
        >
          <span
            className={classNames('name', { ['opacity-50']: 30 === (item.badgeType as unknown as number) })}
            data-testid="name"
          >
            {item.name}
          </span>
          {item.badgeType && (
            <span
              className={classNames(
                { 'bg-introLight text-intro': 10 === (item.badgeType as unknown as number) },
                { 'bg-purple50 text-purple404': 20 === (item.badgeType as unknown as number) },
                { 'bg-orange50 text-orange500 opacity-50': 30 === (item.badgeType as unknown as number) },
                'badge rounded p-1 text-xs font-semibold'
              )}
              data-testid="badge"
            >
              {printBadgeType[item.badgeType]}
            </span>
          )}
        </RelLink>
      ) : (
        <span
          className={classNames(
            'name accTitle flex border-b border-b-border px-4 py-4 text-base font-semibold text-black900',
            { ['opacity-50']: 30 === (item.badgeType as unknown as number) }
          )}
          data-testid="name"
        >
          {item.name}
        </span>
      )}
    </li>
  );
};
