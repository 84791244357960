import { posFormService } from '@/src/store/services/pos-form-service';
import { combineReducers } from 'redux';
import { chequeService } from './services/cheque-service';
import { insertCommentService } from './services/insert-comment-service';
import { otpService } from './services/otp-service';
import { posListService } from './services/pos-list-service';
import { recourseService } from './services/recourse-service';
import { smeCampaignsService } from './services/sme-campaigns-service';
import { smeService } from './services/sme-service';
import { subscribeService } from './services/subscribe-service';
import chequeSlice from './slices/cheque-slice';
import posListSlice from './slices/pos-list-slice';
import { authService } from './services/auth-service';
import { userService } from './services/user-service';

export default combineReducers({
  [otpService.reducerPath]: otpService.reducer,
  [chequeService.reducerPath]: chequeService.reducer,
  [posListService.reducerPath]: posListService.reducer,
  [posFormService.reducerPath]: posFormService.reducer,
  [smeService.reducerPath]: smeService.reducer,
  [recourseService.reducerPath]: recourseService.reducer,
  cheque: chequeSlice,
  posList: posListSlice,
  [smeCampaignsService.reducerPath]: smeCampaignsService.reducer,
  [subscribeService.reducerPath]: subscribeService.reducer,
  [insertCommentService.reducerPath]: insertCommentService.reducer,
  [authService.reducerPath]: authService.reducer,
  [userService.reducerPath]: userService.reducer,
});
