import { Pages } from '@/src/interfaces/pages-route';
import { getDeviceType } from '@/src/store/get-device-type';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import SmeDetailList = Pages.SmeDetailList;
import { generateGUID } from '@/lib/generate-guid';

export const smeService = createApi({
  reducerPath: 'smeService',
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers) => {
      headers.set('device', getDeviceType());
      headers.set('CorrelationId', generateGUID());
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSmeList: builder.query<
      Pages.SmeListList.ResponseBody,
      Pages.SmeListList.RequestQuery & Pages.SmeListList.RequestHeaders
    >({
      query: (params) => ({
        url: '/pages/sme/list',
        method: 'GET',
        params,
        headers: {
          device: params.device,
        },
      }),
    }),
    getBankList: builder.query<
      Pages.SmeListDetail.ResponseBody,
      Pages.SmeListDetail.RequestQuery & Pages.SmeListDetail.RequestHeaders & Pages.SmeListDetail.RequestParams
    >({
      query: (params) => ({
        url: `/pages/sme/${params.bankSeoUrl}/list?Maturity=${params.Maturity}&Amount=${params.Amount}`,
        method: 'GET',
        headers: {
          device: params.device,
        },
      }),
    }),
    getSmeDetail: builder.query<Pages.SmeDetailList.ResponseBody, Pages.SmeDetailList.RequestQuery>({
      query: ({ Amount, Maturity, ProductSeoUrl, BankSeoUrl }: SmeDetailList.RequestQuery) => ({
        url: `/pages/sme/detail?BankSeoUrl=${BankSeoUrl}&ProductSeoUrl=${ProductSeoUrl}&amount=${Amount}&maturity=${Maturity}`,
        method: 'GET',
      }),
    }),
    getSmeRedirectProduct: builder.query<
      Pages.SmeRedirectproductList.ResponseBody,
      Pages.SmeRedirectproductList.RequestQuery
    >({
      query: (params: Pages.SmeRedirectproductList.RequestQuery) => ({
        url: `/pages/sme/redirectproduct`,
        params,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetSmeListQuery,
  useLazyGetSmeListQuery,
  useGetSmeDetailQuery,
  useLazyGetSmeDetailQuery,
  useGetBankListQuery,
  useLazyGetBankListQuery,
  useGetSmeRedirectProductQuery,
} = smeService;
