'use client';

import { RefObject, useEffect, useState } from 'react';
import { Menu } from './menu/menu';
import { Top } from './top/top';
import { ResponseMenuItem } from '@/src/interfaces/data-contracts';

export const HeaderMobile = (props: { menuItems: ResponseMenuItem[]; headerRef: RefObject<any> }) => {
  const menuItems = props.menuItems;
  const [openMenu, setOpenMenu] = useState(false);
  useEffect(() => {
    if (openMenu) {
      document.body.classList.add('overflow-hidden');
      props.headerRef.current.classList.remove('sticky');
      props.headerRef.current.classList.add('fixed');
    } else {
      document.body.classList.remove('overflow-hidden');
      props.headerRef.current.classList.remove('fixed');
      props.headerRef.current.classList.add('sticky');
    }
  }, [openMenu]);
  return (
    <>
      <Top openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <Menu menuItems={menuItems} openMenu={openMenu} setOpenMenu={setOpenMenu} />
    </>
  );
};
