import { getDeviceType } from '@/src/store/get-device-type';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generateGUID } from '@/lib/generate-guid';
import { ResponseCompanyDetail, ResponseCompanyInfo } from '@/src/interfaces/data-contracts';

export const authService = createApi({
  reducerPath: 'authService',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers) => {
      headers.set('device', getDeviceType());
      headers.set('CorrelationId', generateGUID());
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // applyForm: builder.mutation<CheckpromissoryApplyFormListData, FormData>({
    //   query: (body) => ({
    //     url: '/pages/checkpromissory/applyForm',
    //     method: 'POST',
    //     body,
    //   }),
    // }),
    // districts: builder.query({
    //   query: (params) => ({
    //     url: `/pages/content/${params.cityId}/districts`,
    //     method: 'GET',
    //   }),
    // }),
    companySectors: builder.query({
      query: () => ({
        url: '/pages/lookup/companySectors',
        method: 'GET',
      }),
    }),
    companyTypes: builder.query({
      query: () => ({
        url: '/pages/lookup/companyTypes',
        method: 'GET',
      }),
    }),
    cities: builder.query({
      query: () => ({
        url: '/pages/lookup/cities',
        method: 'GET',
      }),
    }),
    addCompany: builder.mutation({
      query: (data: ResponseCompanyDetail) => ({
        url: '/AuthServer/AddCompany',
        method: 'POST',
        body: data,
      }),
    }),
    deleteCompany: builder.mutation({
      query: (id: any) => ({
        url: `/AuthServer/DeleteCompany?id=${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCompanySectorsQuery,
  useCompanyTypesQuery,
  useCitiesQuery,
  useAddCompanyMutation,
  useDeleteCompanyMutation,
} = authService;
