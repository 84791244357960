import { CheckpromissoryApplyFormCreatePayload } from '@/src/interfaces/data-contracts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  chequeData: CheckpromissoryApplyFormCreatePayload | null;
} = {
  chequeData: null,
};

//
const chequeSlice = createSlice({
  name: 'cheque',
  initialState,
  reducers: {
    setChequeData: (
      state,
      action: PayloadAction<CheckpromissoryApplyFormCreatePayload>
    ) => {
      state.chequeData = action.payload;
    },
  },
});

export const { setChequeData } = chequeSlice.actions;
export default chequeSlice.reducer;
