import { getCookiesClient } from '@/src/utils/cookie-parser';
import { useEffect, useState } from 'react';
import { UserProfileIcon } from '../ui/icons/icons';
import cx from 'classnames';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';

type UserLoginState = 'accessing' | 'loggedIn' | 'loggedOut';
export const UserAvatar = () => {
  const [userLoginState, setUserLoginState] = useState<UserLoginState>('accessing');
  const [avatarInitial, setAvatarInitial] = useState(null);
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    const sessionCookie = getCookiesClient();
    if (sessionCookie && sessionCookie['session.userInfo']) {
      const userInitial = JSON.parse(sessionCookie['session.userInfo']).name[0].toUpperCase();
      setUserLoginState('loggedIn');
      setAvatarInitial(userInitial);
    } else {
      setAvatarInitial(null);
      setUserLoginState('loggedOut');
    }
  }, [avatarInitial, userLoginState, pathname]);

  const LoginMenuItem = () => {
    return (
      <div className="flex shrink-0 items-center gap-[6px] text-sm font-semibold text-purple404">
        <Link href="/giris">
          <UserProfileIcon width={20} height={20} />
        </Link>
        <Link href="/giris" className="hidden md:block">
          <div>Oturum Aç</div>
        </Link>
      </div>
    );
  };

  const handleGoToMember = () => {
    setUserLoginState('loggedOut');
    router.push('/uye');
  };

  return (
    <div
      className={cx('flex h-8 w-8 cursor-pointer items-center justify-center text-base font-semibold md:h-10 md:w-10', {
        'rounded-full bg-primary100 text-primary600': avatarInitial,
      })}
      onClick={() => avatarInitial && handleGoToMember()}
    >
      {userLoginState === 'accessing' ? <></> : userLoginState === 'loggedIn' ? avatarInitial : LoginMenuItem()}
    </div>
  );
};
