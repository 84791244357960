import classNames from 'classnames';
import Image from 'next/image';
import { InnerContainer } from '../../common/inner-container';
import { getCdnPath } from '@/src/utils/get-cdn-path';
import { ETBIS } from '../etbis';

export const Kvkk = ({ className, ...props }: any) => {
  return (
    <InnerContainer>
      <div
        className={classNames('container mx-auto flex flex-row md:border-t', className)}
        {...props}
        data-testid="footerkvkk"
      >
        <div className="flex w-full flex-col justify-between space-y-4 md:flex-row md:space-y-0">
          <div className="flex flex-col items-center md:items-start md:space-y-0" data-testid="companyInfo">
            <div className="mb-1 text-sm font-semibold text-black900 md:mb-0 md:text-xs" data-testid="name">
              Hangisi İnternet ve Bilgi Hizmetleri A.Ş.
            </div>
            <div className="text-center  text-[11px] text-black700 md:text-left">
              <span data-testid="address">
                Anel İş Merkezi Yamanevler Mah. Site Yolu Cad. No:5/6 34768 Ümraniye/ İstanbul MERSİS NO:
                0524052734800011{' '}
              </span>
              <br />
              <span data-testid="phone"></span>
              Telefon: 0850 644 26 44
            </div>
          </div>
          <div className="flex justify-between gap-5 md:justify-center md:justify-items-start" data-testid="logoList">
            <ETBIS />
            <Image
              src={getCdnPath('/footer/SSL.png')}
              alt={'SSL'}
              style={{ objectFit: 'contain' }}
              width={52}
              height={52}
              data-testid="SSL"
            />
            <Image
              src={getCdnPath('/footer/KVKK.png')}
              alt={'KVKK'}
              style={{ objectFit: 'contain' }}
              width={52}
              height={52}
              data-testid="KVKK"
            />
            <Image
              src={getCdnPath('/footer/ISAE-3403.png')}
              alt={'ISAE 3403'}
              style={{ objectFit: 'contain' }}
              width={52}
              height={52}
              data-testid="ISAE3403"
            />
            <Image
              src={getCdnPath('/footer/ISO27001.png')}
              alt={'ISO 27001'}
              style={{ objectFit: 'contain' }}
              width={52}
              height={52}
              data-testid="ISO27001"
            />
            {/* <TRGOLogoIcon width={52} height={52} /> */}
          </div>
        </div>
      </div>
    </InnerContainer>
  );
};
