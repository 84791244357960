import { posFormService } from '@/src/store/services/pos-form-service';
import { smeService } from '@/src/store/services/sme-service';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import rootReducer from './reducers';
import { chequeService } from './services/cheque-service';
import { insertCommentService } from './services/insert-comment-service';
import { otpService } from './services/otp-service';
import { posListService } from './services/pos-list-service';
import { recourseService } from './services/recourse-service';
import { smeCampaignsService } from './services/sme-campaigns-service';
import { subscribeService } from './services/subscribe-service';
import { authService } from './services/auth-service';
import { userService } from './services/user-service';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['cheque/setChequeData'],
        ignoredPaths: [
          'payload.InvoiceFrontFace',
          'cheque.chequeData.InvoiceFrontFace',
          'cheque.chequeData.CheckBackFace',
          'cheque.chequeData.CheckFrontFace',
        ],
      },
    }).concat([
      otpService.middleware,
      chequeService.middleware,
      smeService.middleware,
      posListService.middleware,
      posFormService.middleware,
      recourseService.middleware,
      smeCampaignsService.middleware,
      subscribeService.middleware,
      insertCommentService.middleware,
      authService.middleware,
      userService.middleware,
    ]),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
